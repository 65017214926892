import React, { useContext } from "react";
import "./Header.css";
import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo2.png'
import authData from '../../utils/userContext'
import { GiHamburgerMenu } from 'react-icons/gi'
import Sidebar from "./slidebar";


const Header = () => {
  const { authStatus } = useContext(authData)

  return (
    <div className="header-main">
      <div className="header-submin">
        <div className="header-headings">
          <Link to="/">
            <img
              src={Logo}
              alt=""
              className="logo-img"
            />
          </Link>
          <Sidebar/>
          <a href="/#book-a-service" style={{ textDecoration: 'none', color: "unset" }}onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}><p className="navlinks">Book Service</p></a> <div className="divider">

        <span className="linediv"></span>
      </div>
          <Link to="/aboutus" style={{ textDecoration: 'none', color: "unset" }}onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}><p className="navlinks">About Us</p></Link> <div className="divider">

        <span className="linediv"></span>
      </div>
          <Link to="/contact-us" style={{ textDecoration: 'none', color: "unset" }}onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}><p className="navlinks">Contact Us</p></Link> <div className="divider">

        <span className="linediv"></span>
      </div>
          <Link to="/partner-with-us" style={{ textDecoration: 'none', color: "unset" }}onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}><p className="navlinks">Partner With Us</p></Link>
        </div>
        {
          authStatus ?
            <div className="header-btns">
              <Link to="/user/orders"><button className="btn-1">Dashboard</button></Link>
            </div>
            :
            <div className="header-btns">
              <Link to="/signup"><button className="btn-1"onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}>Sign up</button></Link>
              <Link to="/login"><button className="btn-2"onMouseEnter={(e) => {
          e.target.style.transform = 'scale(1.1)';
        }}
        onMouseLeave={(e) => {
          e.target.style.transform = 'scale(1)';
        }}>Sign in</button></Link>
            </div>
        }
        <div className="ham-burg-head">
          <GiHamburgerMenu style={{fontSize: '27px', color:'var(--primaryColor)'}} />
        </div>
      </div>
    </div>
  );
};

export default Header;