import React, { useEffect } from "react";
import "./Faq.css";
import $ from 'jquery'
import {motion} from 'framer-motion'

const Faq = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [])

  return (
    <motion.div className="faq-god"
    initial ={{opacity:0}}
    animate ={{opacity:1}}
    exit={{opacity:0}}>
      <div className="faq-sec1">
        <div className="fs1-left">
          <span>Frequently Asked Questions</span>
          <p>
            All you need to know about our company, our services, and our
            platform
          </p>
        </div>
        <div className="fs1-right"></div>
      </div>
      <div className="faq-sec2">
        <div className="faq-sec2-sub">
          <div className="fs2s-cards">
            <div className="fs2scard">
              <p>About Cargo Onboard</p>
            </div>
            <div className="fs2scard">
              <p>Cargo Onboard's Platform</p>
            </div>
            <div className="fs2scard">
              <p>Cargo Onboard's Services</p>
            </div>
            <div className="fs2scard">
              <p>Payments and Pricing</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Faq;
