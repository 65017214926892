import React, { useEffect } from "react";
import "./Home.css";
// // import vec1 from "../Assets/vec1.svg";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, EffectCoverflow } from "swiper";
// // import { BiLocationPlus } from "react-icons/bi";
import "swiper/css";
import "swiper/css/effect-coverflow";
import Form1 from "../Components/Home/Form1";
import Frontmainpage from '../Assets/front-main-page.png'

import $ from 'jquery'
 
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';



const Home = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [])
  const controlStyles = {
    color: 'black',
  }
  return (
    <motion.div className="main-home-page"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ y: window.innerWidth, transition: { duration: 0.5 } }}
      transition={{ type: "spring", stiffness:"50"  }}>
      {/*Section 1*/}
      {/* <Gallery/> */}

      <div className="home-b1">
        <div className="home-b1le">
        <img src={Frontmainpage} className="b1-vec2" alt="" />
          <h3 className=" ">
            Welcome To <span className="halfhead">Cargo Onboard</span>
          </h3>
          <p className="text-center my-0">Seamless Global Freight Services, Cargo Onboard</p>


        </div>

        <div className="home-b1ri  ">
          <p className="parahero">
            Cargo Onboard is a customs clearance and <br />international freight forwarding company. <br />
            The company has its own infrastructure <br />and a global network of agents.
          </p>
          <img src={Frontmainpage} className="b1-vec" alt="" />
        </div>
      </div>
      {/*Section 2*/}
      <Form1 />
      {/*Section 2.1*/}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={"/GIF.png"} style={{ width: '100%', margin: 'auto' }} className="my-3" />
      </div>
      {/*Section 3*/}
      <div className="home-b3">
        <div className="b3left">
          <span className="headfeature">Core functionalities.</span>
          <p>
          Cargo Onboard offers advanced container tracking, flexible booking options, easy instant reservations, and outstanding worldwide logistics expertise through our vast collection of owned resources.

          </p>
          {/* <button className="get-quote-btn b3-btn"onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.1)';
              }}
                onMouseLeave={(e) => {
                  e.target.style.transform = 'scale(1)';
                }} >View More</button> */}
        </div>
        <div className="b3right">
          {/* <div className="b3swiper">
            <Swiper
              slidesPerView="auto"
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              loopedSlides={3}
              grabCursor={true}
              effect="coverflow"
              autoplay={{
                delay: 2000,
              }}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              modules={[Autoplay, EffectCoverflow]}
            >
              <SwiperSlide style={{ borderRadius: "16px" }}>
                <div className="swiper-card">
                  <div className="swiper-card-img">
                    <img
                      src="https://images.unsplash.com/photo-1592903297149-37fb25202dfa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lmdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    />
                  </div>
                  <div className="swiper-card-info">Our Merchants</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-card">
                  <div className="swiper-card-img">
                    <img
                      src="https://images.unsplash.com/photo-1592903297149-37fb25202dfa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lmdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    />
                  </div>
                  <div className="swiper-card-info">Our Merchants</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-card">
                  <div className="swiper-card-img">
                    <img
                      src="https://images.unsplash.com/photo-1592903297149-37fb25202dfa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lmdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                      alt=""
                    />
                  </div>
                  <div className="swiper-card-info">Our Merchants</div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
          <img  src={"/ourcore.png"} style={{ width: '75%', margin: 'auto', marginLeft: "0", paddingLeft: "0", position: "relative", right: "-70px" }} />
        </div>
      </div>
      {/*Section 4*/}
      <div className="home-b4">
        <div className="b4-left">
          <div className="b4l-1">
          <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : -100 }}
            transition={{ duration: 1 }} className="b4l-card">
              <div className="flex">

                <img src={"/deeptracking.png"} className="op-sim" />
                <span>Deep Tracking</span>

              </div>
              <p>Advanced container tracking for complete visibility and predictability of your shipment.</p>
            </motion.div>)}
       </InView>
       <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : 100 }}
            transition={{ duration: 1 }} className="b4l-card">
              <div className="flex">
                <img src={"/flexibility.png"} className="op-sim" />
                <span>Flexibility and Versatility</span>
              </div>
              <p>We provide flexible services, allowing you to arrange orders for when you are free.</p>
            </motion.div>
            )}
            </InView>
          </div>
          <div className="b4l-1">

            <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : -100 }}
            transition={{ duration: 1 }} className="b4l-card">
              <div className="flex">
                <img src={"/instantbooking.png"} className="op-sim" />
                <span>Instant Booking</span>
              </div>
              <p>Efficiently book your shipment in few easy steps.</p>

              </motion.div>
            )}
            </InView>
            <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : 100 }}
            transition={{ duration: 1 }} className="b4l-card">
              <div className="flex">
                <img src={"/quality.png"} className="op-sim" />
                <span>Quality Assurance</span>
              </div>
              <p>We are a worldwide logistics company with a network of connected owned assets located all over the world.</p>

              </motion.div>
            )}
            </InView>
          </div>
        </div>
        <div className="b4-right">
          <span className="b4r-heading">
            Why choose Cargo Onboard ? Your believe is more important for us.
          </span>
          <p className="b4r-description">
            With the help of digital platform and dedicated team, You can take complete control of your supply chain processes.
          </p>
          <div className="b4r-card">
            <div className="b4rc">
              <span>15M+</span>
              <p>More Users</p>
            </div>
            <div className="b4rc">
              <span>100+</span>
              <p>Delivery Cars</p>
            </div>
            <div className="b4rc">
              <span>20+</span>
              <p>Intercity Trucks</p>
            </div>
          </div>
        </div>
      </div>
      {/*Section 5*/}
      <div className="home-b5">
      <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : -100 }}
            transition={{ duration: 1 }} className="hb51">
          <div>
            <img
              src="https://images.unsplash.com/photo-1578575437130-527eed3abbec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8c2hpcHBpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
              alt=""
              className="hb51-img1"
            />
          </div>

          <img
            src="https://images.unsplash.com/photo-1605745341112-85968b19335b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8c2hpcHBpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            alt=""
            className="hb51-img2"
          />
        </motion.div>
            )}
            </InView>
            <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ?0 : 100 }}
            transition={{ duration: 1 }} className="hb52">
          <span>
            Experience the essence of Cargo Onboard's work through our mesmerizing gallery.
          </span>
          <p>
            With a solid track record of 20 years in the business, we bring a wealth of experience that translates into professional and conscientious handling of all shipments.

          </p>
          <Link to="/gallery">
            <button onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.1)';
              }}
                onMouseLeave={(e) => {
                  e.target.style.transform = 'scale(1)';
                }} >View More</button></Link>
        </motion.div>
            )}
            </InView>
        <InView
    threshold={0}  
  >{({ inView, ref }) => (
            <motion.div  ref={ref}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ?0 : 100 }}
            transition={{ duration: 1 }}  className="hb53">
          <img
            src="/shipleft.jpg"
            alt=""
            className="hb53-img1"
          />
          <div>
            <img
              src="/containersleft.jpg"
              alt=""
              className="hb53-img2"
            />
          </div>
          </motion.div>
            )}
            </InView>
      </div>
      {/*Section 6*/}

      <div className="hb62">
        <div className="hb612">
          <span>People say about Cargo Onboard</span>
          <p>all from clients, we display here so you can see.</p>
        </div>
        <Carousel slide
          className="carousel2"
          interval={2000}


        >
          <Carousel.Item className="carouselitem">
            <div className="home6-card revi1">
              <div style={{ height: "150px" }}>
                <p className="text-center"> Very useful application, good services. </p>
                <div className="info-avatar">
                  <span>-Alexandro</span>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carouselitem">
            <div className="home6-card revi2">
              <div style={{ height: "150px" }}>
                <p className="text-center">Impressed by Cargo Onboard's' commitment to excellence. The adaptable booking solutions allowed me to plan shipments on my terms. The container tracking system provided real-time insights, and the instant reservations streamlined the process. With their worldwide logistics network, I felt confident in the safety of my goods</p>
                <div className="info-avatar">
                  <span>-Uddesh</span>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* Add more Carousel.Items as needed */}
        </Carousel>
      </div>


    </motion.div>
  );
};

export default Home;
