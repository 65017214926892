import React, { Fragment, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Orderplaceimg from "../../Assets/order_placed.png";
import "./conf.css";
import { Link } from "react-router-dom";
import authData from '../../utils/userContext'

const MyVerticallyCenteredModal = (props) => {
  const {authStatus} = useContext(authData)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.modType === "OrderConfirm" ? (
        <Fragment>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Order Summary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="cm-god">
              <div className="cm-fromto">
                <div className="fromto1">
                  <div className="huhuhu">
                    <span>Shipper</span>
                    <span className="port-name">
                      {props?.shippingCompany[0]?.name}
                    </span>
                  </div>
                  <div className="to"></div>
                  <div className="huhuhu">
                    <span>Source Port </span>
                    <span className="port-name">
                      {props?.sourcePort[0] && props.sourcePort[0]?.name}
                    </span>
                  </div>
                  <div className="to"></div>

                  <div className="huhuhu">
                    <span>Destination Port </span>
                    <span className="port-name">
                      {props?.destinationPort[0] &&
                        props.destinationPort[0]?.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="cm-deets">
                <div className="cm-one">
                  {" "}
                  <div className="display-info">
                    <span>Item Dimensions</span> <p>{props?.itemDimensions}&nbsp;{
                      props?.bookingType === "break bulk" ?
                      <span>cm<sup>3</sup></span>
                      :
                      "feet"
                    }</p>
                  </div>
                  <div className="display-info">
                    <span>Delivery Date</span>
                    <p>{props?.deliveryDateRequired}</p>
                  </div>
                  <div className="display-info">
                    <span>Booking Type</span>
                    <p style={{textTransform: "capitalize"}}>{props?.bookingType}</p>
                  </div>
                  <div className="display-info">
                    <span>Booking Cost</span>
                    <p>$ {props?.bookingCost}</p>
                  </div>
                  {/* <div className="display-info">
                    <span>Agent</span>
                    <p>{props?.agent}</p>
                  </div> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          {
            authStatus ? 
            <Button
              style={{
                backgroundColor: "#fb9263",
                outline: "none",
                border: "2px solid #fb9263",
              }}
              onClick={props.orderCreate}
            >
              Confirm
            </Button>
            :
           <Link to="/login">
           <Button
              style={{
                backgroundColor: "#fb9263",
                outline: "none",
                border: "2px solid #fb9263",
              }}
            >
              Confirm
            </Button>
           </Link>
          }
            
            <Button
              style={{
                backgroundColor: "#fff",
                outline: "none",
                color: "#fb9263",
                border: "2px solid #fb9263",
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Fragment>
      ) : (
        <Fragment>
          <Modal.Body>
            <div className="orplace-succ-screen">
              <img className=" flex justify-center Tick align-center"  src={"/Tick.png"} />
              <h2 className="cong flex justify-center">CONGRATULATIONS</h2>
              <h5 className="flex justify-center">your order has been placed </h5>
              
              <br />
              <Link to="/user/orders">
                <Button className="butt flex justify-center"
                  style={{
                    backgroundColor: "#fb9263",
                    outline: "none",
                    border: "2px solid #fb9263",
                    
                    
                  }}
                >
                  View Order
                </Button>
              </Link>
            </div>
          </Modal.Body>
        </Fragment>
      )}
    </Modal>
  );
};

export default MyVerticallyCenteredModal;
