import React, { useState, useContext, useEffect, Fragment } from "react";
import "./UserDashboard.css";
import authData from "../../utils/userContext";
import axios from "axios";
import "react-step-progress-bar/styles.css";
import Loader from "../../SharedComponents/Loader";
import $ from 'jquery'

const UserDashboard = () => {
  const [tabTitle, setTabtitle] = useState("all");
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(authData);

  useEffect(() => {
    if (user && user._id) {
      setIsLoading(true);
      axios({
        method: "GET",
        url: `https://rish-shipping-backend-theta.vercel.app/api/booking/${user._id}/${tabTitle}`,
      }).then((data) => {
        console.log(data.data)
        setIsLoading(false);
        setBookingData(data.data);
      });
    }
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [user, tabTitle]);

  const onChangeTabTitle = (val) => {
    setTabtitle(val);
  };

  return (
    <div>
      <h3 className="bookings-ud-head">My Orders</h3>
      <h6 className="bookings-ud-sd-head">
        Track your open orders & view the summary of your past orders
      </h6>
      <select
        className="status-dropdown-filter"
        onChange={(e) => {
          onChangeTabTitle(e.target.value);
        }}
        value={tabTitle}
      >
        <option value="all">All Orders</option>
        <option value="Pending">Pending</option>
        <option value="Confirmed">Confirmed</option>
        <option value="Completed">Completed</option>
        <option value="Cancelled">Cancelled</option>
      </select>
      <div className="booking-data">
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            {bookingData?.data?.map((doc) => (
              <div key={doc.id} className="booking-data-card">
                <h6 className="category-name-card-booking">
                  {doc?.status}
                </h6>
                <div className="booking-data-card-header">
                  <h6>PO Number: {doc?.poNo}</h6>
                  <h6>Booking On: {new Date(doc?.createdAt).toDateString()}</h6>
                </div>
                <div className="booking-data-card-body">
                  <div>
                    <h6>Source Port: {doc?.sourcePort?.name}</h6>
                    <h6>Destination Port: {doc?.destinationPort?.name}</h6>
                    <h6>Shipping Company: {doc?.shippingCompany?.name}</h6>
                    <h6>Booking Cost: $ {doc?.bookingCost}</h6>
                    <h6>Agent Name: {doc?.shippingCompany?.category?.name}</h6>
                  </div>
                  <div>
                    <h6>Booking Type: {doc?.bookingType}</h6>
                    <h6>Good Type: {doc?.goodsType}</h6>
                    {/* <h6>PO Number: {doc?.poNo}</h6> */}
                    {doc?.pnr ? <h6>PNR Number: {doc?.pnr}</h6> : null}
                    {
                      doc?.status !== "Completed" ?
                        <>
                          {doc?.trackingLink ? (
                            <h6>Tracking Link: <a href={doc?.trackingLink} target="_blank"  >Track Your Order</a></h6>
                          ) : null}
                        </>
                        :
                        null
                    }



                    <h6>Item Dimension: {doc?.itemDimensions}&nbsp;{
                      doc?.bookingType === "Break bulk" ?
                        <span>cm<sup>3</sup></span>
                        :
                        "feet"
                    }</h6>
                  </div>
                </div>
                <div className="booking-data-card-bottom">
                  {
                    doc?.status === "Completed" ?
                      <h6>
                        Delivered Date:{" "}
                        {new Date(doc?.deliveredOn).toDateString()}
                      </h6>
                      :
                      <h6>
                        Estimated Dilevery Date:{" "}
                        {new Date(doc?.deliveryDateRequired).toDateString()}
                      </h6>
                  }

                  {doc?.document?.url ? (
                    <h6>
                      Telex Document:{" "}
                      <a href={doc?.document?.url} target="_blank">
                        {doc?.document?.name}
                      </a>
                    </h6>
                  ) : null}
                </div>
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
