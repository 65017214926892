import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Form1.css";
import { Link } from "react-router-dom";
// Changes Made:Mrudul
import { Modal, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";

const Form1 = () => {

  /* Changes Made:Mrudul */
  const goodsTypes = [
    'Automobiles',
    'Electronics',
    'Pharmaceuticals',
    'Machinery',
    'Textiles',
    'Chemicals',
    'Agricultural Products',
    'Metals and Minerals',
    'Plastics',
    'Food and Beverages',
    'Wood and Wood Products',
    'Gems and Jewellery',
    'Apparel and Clothing Accessories',
    'Medical Devices',
    'Artwork and Antiques',
    'Furniture',
    'Cosmetics',
    'Paper and Paper Products',
    'Construction Materials',
    'Toys and Games',
    'Sports Equipment',
    'Stationery and Office Supplies',
    'Musical Instruments',
    'Books and Publications',
    'Household Goods',
    'Packaging Materials',
    'Pet Supplies',
    'Industrial Equipment',
    'Leather Goods',
    'Footwear',
    'Baby Products',
    'Others'
  ];

  // validation of 6 months date
  const today = new Date();
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(today.getMonth() + 6);

  const { register, handleSubmit, watch, errors } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [portsshowmodal, setShowPortModal] = useState(false) //Changes Made:Mrudul

  const [isFormValid, setIsFormValid] = useState(false);
  const [ports, setPorts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shipType, setShipType] = useState("break bulk");
  const [breakbulk, setBreakbulk] = useState({
    destinationPort: "",
    destinationPortName: "",
    sourcePort: "",
    sourcePortName: "",
    space: "",
    weight: "",
    needToarive: "",
    typeOfGood: "",
    OtherTypeofGood: ""
  });

  const [container, setContainer] = useState({
    destinationPort: "",
    destinationPortName: "",
    sourcePort: "",
    sourcePortName: "",
    needToarive: "",
    typeOfGood: "",
    containerType: "",
    OtherTypeofGood: ""
  });
  /*
  const validateForm = () => {
    
    const isContainerValid =
      container.sourcePort.trim() !== '' &&
      container.destinationPort.trim() !== '' &&
      container.containerType.trim() !== '' &&
      container.needToarive.trim() !== '' &&
      container.typeOfGood.trim() !== '';
  
    const isBreakbulkValid =
      breakbulk.sourcePort.trim() !== '' &&
      breakbulk.destinationPort.trim() !== '' &&
      breakbulk.space.trim() !== '' &&
      breakbulk.needToarive.trim() !== '' &&
      breakbulk.typeOfGood.trim() !== '';
  
    const isValid = isContainerValid || isBreakbulkValid;
    setIsFormValid(isValid);
  };
  */
  /*
  const validateForm = () => {
    const isValid = sourcePort.trim() !== '' &&
      destinationPort.trim() !== '' &&
      dimensions.trim() !== '' &&
      dueDate.trim() !== '' &&
      goodType.trim() !== '';
  
    setIsFormValid(isValid);
  };
  */
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("https://rish-shipping-backend-theta.vercel.app/admin/api/ports")
      .then((res) => {
        if (res.data) {
          setPorts(res.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (name, e) => {
    // Changes Made :Mrudul
    const selectedValue = e.target.value;
    if (
      name === "sourcePort" &&
      selectedValue === breakbulk.destinationPort
    ) {
      // Display an error message or handle the invalid selection
      OpenPortModal();
      return;
    }

    if (
      name === "destinationPort" &&
      selectedValue === breakbulk.sourcePort
    ) {
      // Display an error message or handle the invalid selection
      OpenPortModal()
      return;
    }
    setBreakbulk((aa) => {
      //validateForm(breakbulk, container);
      return { ...aa, [name]: e.target.value };
    });
  };

  const handleChangeContainerDetails = (name, e) => {
    // Changes Made :Mrudul
    const selectedValue = e.target.value;
    if (
      name === "sourcePort" &&
      selectedValue === container.destinationPort
    ) {
      // Display an error message or handle the invalid selection
      OpenPortModal();
      return;
    }

    if (
      name === "destinationPort" &&
      selectedValue === container.sourcePort
    ) {
      // Display an error message or handle the invalid selection
      OpenPortModal();
      return;
    }
    setContainer((aa) => {
      // Changes Made :Mrudul

      //validateForm(breakbulk, container);
      return { ...aa, [name]: e.target.value };
    });
  };
  // Changes Manually Added :Mrudul
  const handleButtonClick = () => {
    if (
      (shipType === "break bulk" &&
        (breakbulk.sourcePort.trim() === '' ||
          breakbulk.destinationPort.trim() === '' ||
          breakbulk.space.trim() === '' ||
          breakbulk.needToarive.trim() === '' ||
          //breakbulk.typeOfGood.trim() === ''||
          breakbulk.weight.trim() === '' ||
          //breakbulk.OtherTypeofGood.trim()===''
          (breakbulk.typeOfGood !== "Others" && breakbulk.typeOfGood.trim() === '') ||
          (breakbulk.typeOfGood === "Others" && breakbulk.OtherTypeofGood.trim() === '')
        )

      ) ||
      (shipType !== "break bulk" &&
        (container.sourcePort.trim() === '' ||
          container.destinationPort.trim() === '' ||
          container.containerType.trim() === '' ||
          container.needToarive.trim() === '' ||
          (container.typeOfGood !== "Others" && container.typeOfGood.trim() === '') ||
          (container.typeOfGood === "Others" && container.OtherTypeofGood.trim() === '')
          //container.typeOfGood.trim() === ''||
          //container.OtherTypeofGood.trim()==='')

        )
      )) {
      //alert("Please fill in all fields.");
      // Changes Made: Mrudul
      openModal()
    }
  };
  // Changes Made :Mrudul
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const OpenPortModal = () => {
    setShowPortModal(true)
  }
  const closePortModal = () => {
    setShowPortModal(false)
  }
  /*
  const handleChange = (name, e) => {
    const updatedBreakbulk = { ...breakbulk, [name]: e.target.value };
    setBreakbulk(updatedBreakbulk);
    validateForm(updatedBreakbulk, container);
  };
  
  const handleChangeContainerDetails = (name, e) => {
    const updatedContainer = { ...container, [name]: e.target.value };
    setContainer(updatedContainer);
    validateForm(breakbulk, updatedContainer);
  };
  
 */
  return (
    <div className="home-b2" id="book-a-service">
      <div className="b2-form-main">
        <div className="b2-form">
          <div className="b2-form-main">
            <h3>Find your best shipping option in 4 seconds</h3>
            <p>
              Compare dozens of shipping deals and choose what’s best for you
            </p>
            <div style={{ margin: "20px 0", display: "flex" }}>
              <button
                style={
                  shipType === "Container"
                    ? { backgroundColor: "var(--primaryColor)" }
                    : { backgroundColor: "white", color: "var(--primaryColor)" }
                }
                onClick={() => {
                  setShipType("Container");
                }}
                className="form1-btn"
              >
                Container
              </button>
              &nbsp;
              <button
                style={
                  shipType === "break bulk"
                    ? { backgroundColor: "var(--primaryColor)" }
                    : { backgroundColor: "white", color: "var(--primaryColor)" }
                }
                onClick={() => {
                  setShipType("break bulk");
                }}
                className="form1-btn"
              >
                Break Bulk
              </button>
            </div>
          </div>
          {shipType === "Container" ? (
            <div className="b2-form-input">
              <select
                id="business"
                name="business"
                className="form-dropdownss"
                value={container.sourcePort}
                onChange={(e) => {
                  handleChangeContainerDetails("sourcePort", e);
                }}
              >
                <option value="">Source Port</option>
                {ports &&
                  ports.sort((a, b) => a.country.localeCompare(b.country))//Changes Made:Mrudul
                    .map((doc) => (
                      // Added {doc.country}:Mrudul
                      <option value={doc._id}>{`Port of ${doc.name} , ${doc.country}`}</option>
                    ))}
              </select>
              <select
                id="business"
                name="business"
                className="form-dropdownss"
                value={container.destinationPort}
                onChange={(e) => {
                  handleChangeContainerDetails("destinationPort", e);
                }}
              >
                <option value="">Destination Port</option>
                {ports &&
                  ports.sort((a, b) => a.country.localeCompare(b.country))// Changes Made:Mrudul
                    .map((doc) => (
                      // Added {doc.country}:Mrudul
                      <option value={doc._id}>{`Port of ${doc.name},  ${doc.country}`}</option>
                    ))}
              </select>
              <select
                id="business"
                name="business"
                className="form-dropdownss"
                value={container.containerType}
                onChange={(e) => {
                  handleChangeContainerDetails("containerType", e);
                }}
              >
                <option value="">Select Container Type</option>
                <option value={20}>20 Feet</option>
                <option value={40}>40 Feet</option>
              </select>
              <div className="form-input typeofgood">
                {/*<label htmlFor="template-input">Type of Goods</label> */}
                <select
                  type="text"
                  id="template-input"
                  className="form-dropdownss"
                  value={container.typeOfGood}
                  name="typeOfGood"
                  onChange={(e) => {
                    handleChangeContainerDetails("typeOfGood", e);
                  }}>

                  <option value="">Type of Goods</option>
                  {goodsTypes.map((option) => (
                    <option key={option.id} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {container.typeOfGood === 'Others' && (
                  <input
                    type="text"
                    id="template-input"
                    placeholder="Specify type of goods"
                    className="otherinput"
                    value={container.OtherTypeofGood}
                    onChange={(e) => {
                      handleChangeContainerDetails("OtherTypeofGood", e);
                    }}
                  />
                )}
                {/* Changes Made:Mrudul */}
                {/*
              <select
                type="text"
                id="template-input"
                className="form-dropdownss"
                value={container.typeOfGood}
                name="typeOfGood"
                onChange={(e) => {
                  handleChangeContainerDetails("typeOfGood", e);
                }}>
                  
                <option value="">Type of Goods</option>
                {goodsTypes.map((option) => (
                <option key={option.id} value={option}>
                 {option}
                </option>
                  ))}
                </select>
                */}

                {/*
                <input
                  type="text"
                  id="template-input"
                  placeholder=""
                  
                  value={container.typeOfGood}
                  onChange={(e) => {
                    handleChangeContainerDetails("typeOfGood", e);
                  }}
                />
                <label htmlFor="template-input">Type of Goods</label> */}
              </div>
              <div className="form-input">
                <input
                  type="date"
                  id="template-input"
                  placeholder=""
                  value={container.needToarive}
                  //min={new Date().toISOString().split("T")[0]}
                  min={today.toISOString().split("T")[0]}
                  max={sixMonthsFromNow.toISOString().split("T")[0]}

                  onChange={(e) => {
                    handleChangeContainerDetails("needToarive", e);
                  }}
                />
                <label htmlFor="template-input">Need To Arrive</label>
              </div>
            </div>
          ) : (
            <div className="b2-form-input">
              <select
                id="business"
                name="business"
                className="form-dropdownss"
                value={breakbulk.sourcePort}
                onChange={(e) => {
                  handleChange("sourcePort", e);
                }}
              >
                <option value="">Source Port</option>
                {ports &&
                  ports.sort((a, b) => a.country.localeCompare(b.country))//Changes Made:Mrudul
                    .map((doc) => (
                      // Added {doc.country}:Mrudul
                      <option key={doc.id} value={doc._id}>{`Port of ${doc.name},  ${doc.country}`}</option>
                    ))}
              </select>
              <select
                id="business"
                name="business"
                className="form-dropdownss"
                value={breakbulk.destinationPort}
                onChange={(e) => {
                  handleChange("destinationPort", e);
                }}
              >
                <option value="">Destination Port</option>
                {ports &&
                  ports.sort((a, b) => a.country.localeCompare(b.country))// Changes Made:Mrudul
                    .map((doc) => (
                      <option key={doc.id} value={doc._id}>{`Port of ${doc.name}, ${doc.country}`}</option>
                    ))}
              </select>
              <div className="form-input typeofgood">
                {/* Changes Made:Mrudul */}
                <select
                  type="text"
                  id="template-input"
                  className="form-dropdownss"
                  value={breakbulk.typeOfGood}
                  name="typeOfGood"
                  onChange={(e) => {
                    handleChange("typeOfGood", e);
                  }}>

                  <option value="">Type of Goods</option>
                  {goodsTypes.map((option) => (
                    <option key={option.id} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {breakbulk.typeOfGood === 'Others' && (
                  <input
                    type="text"
                    id="template-input"
                    placeholder="Specify type of goods"
                    className="otherinput"
                    value={breakbulk.OtherTypeofGood}
                    onChange={(e) => {
                      handleChange("OtherTypeofGood", e);
                    }}
                  />
                )}
                {/*
                <input
                  type="text"
                  id="template-input"
                  placeholder=""
                  value={breakbulk.typeOfGood}
                  name="typeOfGood"
                  
                  onChange={(e) => {
                    handleChange("typeOfGood", e);
                  }}
                />*/}
                {/* <label htmlFor="template-input">Type of Goods</label>*/}
              </div>

              <div className="form-inpu1">
                <input className="inputweight" type="number"
                  id="template-input" placeholder="Approx Weight in kg" name="weight" value={breakbulk.weight} onChange={(e) => {
                    handleChange("weight", e);
                  }} />

              </div>
              <div className="form-inpu">
                <input
                  className="inputweight"
                  type="number"
                  id="template-input"
                  placeholder="Approx space in cubic cm"
                  value={breakbulk.space}
                  name="space"
                  onChange={(e) => {
                    handleChange("space", e);
                  }}
                />

              </div>
              <div className="form-input">
                <input
                  type="date"
                  id="template-input"
                  placeholder="Select Date to Book"
                  value={breakbulk.needToarive}
                  //min={new Date().toISOString().split("T")[0]}
                  min={today.toISOString().split("T")[0]}
                  max={sixMonthsFromNow.toISOString().split("T")[0]}

                  name="needToarive"
                  onChange={(e) => {
                    handleChange("needToarive", e);
                  }}
                />
                <label htmlFor="template-input">Need To Arrive</label>
              </div>
            </div>
          )}
          {/* Changes Manually Made:'Mrudul' */}
          <div className="b2-form-button">
            {/* {shipType === "break bulk" ? (
              <Link
                to={ `/search?sourcePort=${breakbulk.sourcePort}&destinationPort=${breakbulk.destinationPort}&dimensions=${breakbulk.space}&bookingType=${shipType}&duedate=${breakbulk.needToarive}&goodType=${breakbulk.typeOfGood}`}
              >
                <button  className="get-quote-btn">Get Quotes</button>
              </Link>
            ) : (
              <Link
                to={`/search?sourcePort=${container.sourcePort}&destinationPort=${container.destinationPort}&dimensions=${container.containerType}&bookingType=${shipType}&duedate=${container.needToarive}&goodType=${container.typeOfGood}`}
              >
                <button  className="get-quote-btn">Get Quotes</button>
              </Link>
            )}*/}
            {shipType === "break bulk" ? (
              <Link
                to={
                  breakbulk.sourcePort.trim() !== '' &&
                    breakbulk.destinationPort.trim() !== '' &&
                    breakbulk.space.trim() !== '' &&
                    breakbulk.weight.trim() !== '' &&
                    breakbulk.needToarive.trim() !== '' &&
                    (breakbulk.typeOfGood !== 'Others' && breakbulk.typeOfGood.trim() !== '' || (breakbulk.typeOfGood === 'Others' && breakbulk.OtherTypeofGood.trim() !== ''))
                    ? `/search?sourcePort=${breakbulk.sourcePort}&destinationPort=${breakbulk.destinationPort}&dimensions=${breakbulk.space}&bookingType=${shipType}&duedate=${breakbulk.needToarive}&goodType=${breakbulk.typeOfGood === 'Others' ? breakbulk.OtherTypeofGood : breakbulk.typeOfGood}`
                    : '#'
                  /*
                  breakbulk.typeOfGood.trim() !== ''
    
                  
                  ? breakbulk.typeOfGood === 'Others'
                   ? `/search?sourcePort=${breakbulk.sourcePort}&destinationPort=${breakbulk.destinationPort}&dimensions=${breakbulk.space}&bookingType=${shipType}&duedate=${breakbulk.needToarive}&goodType=${breakbulk.OtherTypeofGood}`
                  : `/search?sourcePort=${breakbulk.sourcePort}&destinationPort=${breakbulk.destinationPort}&dimensions=${breakbulk.space}&bookingType=${shipType}&duedate=${breakbulk.needToarive}&goodType=${breakbulk.typeOfGood}`
                : '#'
                */
                }
              >
                <button className="get-quote-btn" onClick={handleButtonClick}>Get Quotes</button>
              </Link>
            ) : (
              <Link
                to={
                  container.sourcePort.trim() !== '' &&
                    container.destinationPort.trim() !== '' &&
                    container.containerType.trim() !== '' &&
                    container.needToarive.trim() !== '' &&
                    (container.typeOfGood !== 'Others' && container.typeOfGood.trim() !== '' || (container.typeOfGood === 'Others' && container.OtherTypeofGood.trim() !== ''))
                    ? `/search?sourcePort=${container.sourcePort}&destinationPort=${container.destinationPort}&dimensions=${container.containerType}&bookingType=${shipType}&duedate=${container.needToarive}&goodType=${container.typeOfGood === 'Others' ? container.OtherTypeofGood : container.typeOfGood}`
                    : '#'

                }
              >
                <button className="get-quote-btn" onClick={handleButtonClick} onMouseEnter={(e) => {
                  e.target.style.transform = 'scale(1.1)';
                }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = 'scale(1)';
                  }} >Get Quotes</button>
              </Link>
            )}
          </div>


        </div>
        {/* <button className="get-quote-btn" onClick={fetchQuotes}>
                Get Quote
              </button> */}
      </div>
      {/* Changes Made:Mrudul */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please Fill All Fields.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Changes Made:Mrudul */}

      {/* Changes Made:Mrudul */}
      <Modal show={portsshowmodal} onHide={closePortModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Source and Destination Port cannot be Same.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closePortModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Form1;