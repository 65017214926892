import React, { useEffect } from "react";
import "./AboutUs.css";
import image1 from "../../Assets/about-us-banner.png";
import ProfileCard from "./ProfileCard";
import $ from 'jquery'
import {motion} from 'framer-motion'

const AboutUs = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [])
  
  return (
    <motion.div className="aboutus-god"
    initial={{ width: 0 }}
    animate={{ width: "100%" }}
    exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    transition={{ type: "spring", stiffness:"50"  }}
    >
      <div className="aboutus-firstbanner">
        <div className="aboutus-firstleft">
          <p>
            The <span className="comp-name">Cargo Onboard </span>
            Story: <br />
            Becoming Your Partner
            <br /> In Growth, Convenience, And Excellence
          </p>
        </div>
        <div className="aboutus-firstright">
          <img src={image1} />
        </div>
      </div>
      <div className="secondbanner">
        <div className="contentholder-second">
          <div className="heading-secondbanner">OUR IDENTITY</div>
          <div className="secondb-sub">
            <div className="secondb-subr">
              We're a shipping company that cares about our customers.
            </div>
            <div className="secondb-subl">
              We understand that shipping isn't always easy, but we want to make it better for everyone.
              Whether you're shipping something domestically or internationally, we'll work with you to
              make sure your shipment arrives safely and on time. We are a professional shipping company
              at the forefront of the shipping industry. We have years of experience in the industry,
              so we know how to get it done right the first time—and get it done quickly if need be.
              Our services include all types of shipping, including air freight, ocean freight, car
              transportation, and even residential deliveries

              We are a fast-expanding group of innovators and professionals in logistics who are transforming
              global maritime logistics to provide much-needed simplicity, transparency, and high-quality service.
              We believe in treating our customers like family, and we'd love to hear from you!

            </div>
          </div>
        </div>
        <div className="contentholder-second2">
          <div className="heading-ss">OUR MISSION</div>
          <div className="body-ss">
            We aim to build a platform that will prior simplicity and transparency for the shippers.
            By acting as a digital forwarding partner for international shippers, Cargo on board aims
            to balance the advantages of digitalization with committed customer care.

          </div>
        </div>
      </div>
      {/* <div className="aboutus-thirdbanner">
        <div className="thirdbanner-imgholder">
          <img src={image1} alt="" />
        </div>
      </div> */}
      <div className="aboutus-fourthbanner">
        <div className="title-fourth">OUR LEADERSHIP</div>
        <div className="card-holder-abtus">
          <ProfileCard
            src="https://images.unsplash.com/photo-1544639044-4f142ceb6a2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGdpZnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            name="Hero"
            title="CEO"
            className="card1"
            desc="Sanjay comes with extensive business experience through his roles with PwC Strategy Consulting, Solera Partners, and Everstone Capital. He has consulted for numerous Fortune 500 firms and MNC logistics companies, and has also founded and led an NGO."
          />
          <ProfileCard
            src="https://images.unsplash.com/photo-1544639044-4f142ceb6a2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGdpZnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            name="Hero"
            title="CEO"
            desc="Sanjay comes with extensive business experience through his roles with PwC Strategy Consulting, Solera Partners, and Everstone Capital. He has consulted for numerous Fortune 500 firms and MNC logistics companies, and has also founded and led an NGO."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default AboutUs;
