import React from "react";
import "./fotter.css";
import Logo from "../../Assets/logo2.png";
import { Link } from "react-router-dom";

const Fotter = () => {
  return (
    <div className="fotter-main">
      <div className="fotter-section-wrapper">
        <div className="fotter-section1">
          <div className="fotter-sub-section1">
            <div className="fotter-ss11 flex">
              
              <div style={{ display: "flex", alignItems: "center" }}>
              <Link to={"/"}>
                <img src={Logo} alt="logo" />
                </Link>
                <div>
                  <h6>Cargo Onboard</h6>
                </div>
              </div>
              <div className="fotter-ss12">
                <p>
                Swift and Secure Shipment Services 

                </p>
              </div>
              <div></div>
            </div>
          </div>
          <div className="fotter-sub-section2">
            <div className="fotter-linkss-wrapper">
              
              <h6>Quick Links</h6>
              <a href={"/#book-a-service"}>
              <p>Book Service</p>
              </a>
              <a href={"/aboutus"}>
              <p>About Us</p>
              </a>
              <a href={"/contact-us"}>
              <p>Contact Us</p>
              </a>
              <a href={"/partner-with-us"}>
              <p>Partner with us</p>
              </a>
            </div>
          </div>
          <div className="fotter-sub-section3">
            <div className="fotter-linkss-wrapper">

              <h6>Join Us</h6>
              <a href={"/signup"}>
              <p>Register</p></a>
              <a href={"/login"}>
              <p>Login</p></a>
               
              <p>Help & FAQ</p> 
              <p>T&C | Privacy Policy</p>
            </div>
          </div>
          <div className="fotter-sub-section4">
            <div className="fotter-linkss-wrapper">
              <h6>Connect With Us</h6>
              <p>Facebook</p>
              <p>Instagram</p>
              <p>Linkdein</p>
            </div>
          </div>
        </div>
        <div className="fotter-section2">
          <p>All right reserved @cargo-onboard</p>
          <p>
            <span>Term & Condition</span>&nbsp;-&nbsp;
            <span>Privacy Policy</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fotter;
