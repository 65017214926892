import React from 'react'
import "../Gallery/gallery.css"
import { motion } from "framer-motion";

export default function Gallery() {
  return (
    <div>
    <motion.div className="main-home-page"
    initial={{ width: 0 }}
    animate={{ width: "100%" }}
    exit={{ y: window.innerWidth, transition: { duration: 0.5 } }}
    transition={{ type: "spring", stiffness:"50"  }}>
    <div className='gal'>
       <h1 className='heading flex justify-center'>Gallery Of What We Do</h1>
      <div className='port1'><p className='portname'>dubai port</p></div> 
       <div className='imggrp1'> 
       <img className='img1' src="/gallery/img1.jpg" alt="" />
       <img className='img2'src="/gallery/img2.jpg" alt="" />
       </div>
      
       <div className='imggrp2'> 
       <img className='img3' src="/gallery/img3.jpg" alt="" />
       <img className='img4'src="/gallery/img4.jpg" alt="" />
       </div>
       <div className='port2'><p className='portname'>abu-dabi</p></div> 
       <div className='imggrp3'> 
       <img className='img5' src="/gallery/img5.jpg" alt="" />
       <img className='img6'src="/gallery/img6.jpg" alt="" />
       </div>
       
       <div className='imggrp4'> 
       <img className='img7' src="/gallery/img7.jpg" alt="" />
       
       </div>
       <div className='port3'><p className='portname'>frankenmarkt port</p></div> 
       <div className='imggrp5'> 
       <img className='img8' src="/gallery/img8.jpg" alt="" />
       
       </div>
       <div className='imggrp6'> 
       <img className='img9' src="/gallery/img9.jpg" alt="" />
       <img className='img10' src="/gallery/img10.jpg" alt="" />
       </div>
    </div>
    </motion.div>
    </div>
  )
}
