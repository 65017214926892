import React, { useEffect, useContext } from "react";
import { Outlet, Link } from 'react-router-dom'
import "./UserDashboard.css";
import $ from 'jquery'
import authData from "../../utils/userContext";
import { Navigate } from 'react-router-dom'
import {motion} from 'framer-motion'

const UserDashboard = () => {
  const { logout, triggerAuth, authStatus } = useContext(authData);

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [])

  return (
    <motion.div className="userdash-main-god"
    initial ={{opacity:0}}
    animate ={{opacity:1}}
    exit={{opacity:0}}>
      {authStatus ? null : <Navigate to="/" />}
      <div className="userdash-god">
        <div className="details-ud">
          <div className="details-ud-bar">
            <h5 className="details-ud-bar-acc">My Account</h5>
            <div className="suboptions-sidebar-details">
              <h5>My Orders</h5>
              <Link to="orders" style={{ textDecoration: 'none' }}><h6>Orders</h6></Link>
              <Link to="/contact-us" style={{ textDecoration: 'none' }}><h6>Customer Care</h6></Link>
            </div>
            <hr />
            <div className="suboptions-sidebar-details">
              <h5>Profile</h5>
              <Link to="profile" style={{ textDecoration: 'none' }}><h6>Personal Information</h6></Link>
              <h6 style={{ cursor: "pointer" }} onClick={() => {
                logout()
              }}>Logout</h6>
            </div>
            <hr />
          </div>
        </div>
        <div className="bookings-ud">
          <Outlet />
        </div>
      </div>
    </motion.div>
  );
};

export default UserDashboard;
