import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter} from "react-router-dom";

import Header from "./Components/Header/Header";
import Fotter from "./Components/Fotter/Fotter";

import "react-toastify/dist/ReactToastify.css";
import Quote from "./Pages/Quote/Quote";
import { UserProvider } from "./utils/userContext";
import { ToastContainer } from 'react-toastify'
import AnimatedRoutes from "./Components/AnimatedRoutes";

function App() {
  
  return (
    <div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

      />
      <UserProvider>
        <BrowserRouter>
          <Header />
          <AnimatedRoutes/>
          <Fotter />
        </BrowserRouter>
      </UserProvider>
    </div>
  );
}

export default App;
