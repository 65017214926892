import React from 'react'
import { Routes, Route ,useLocation} from "react-router-dom";
import Home from "../Core/Home";
import Login from "../Pages/Login/Login";
import SignUp from "../Pages/SignUp/SignUp";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Faq from "../Pages/FAQ/Faq";
import UserDashboard from "../Pages/UserDashboard/UserDashboard";
import Search from "../Pages/Search/Search";
import Quote from "../Pages/Quote/Quote";
import Gallery from "../Pages/Gallery/Gallery"
import ContactUs from "../Pages/ContactUs/index";
import Partnerenquiry from "../Pages/Partnerenquiry/index";
import Profile from "../Pages/UserDashboard/Profile";
import Orders from "../Pages/UserDashboard/Orders";
import {AnimatePresence} from "framer-motion"
function AnimatedRoutes() {
    const location = useLocation();
  return (
     <AnimatePresence>
    <Routes location ={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/partner-with-us" element={<Partnerenquiry />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="user" element={<UserDashboard />}>
              <Route path="orders" element={<Orders />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Routes>
          </AnimatePresence>
  )
}

export default AnimatedRoutes
