import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./Search.css";
import authData from "../../utils/userContext";
import { MyVerticallyCenteredModal } from "./RatingsModal";
import ConfirmationModal from "./ConfirmationModal";
import Loader from "../../SharedComponents/Loader";

const Form1 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ports, setPorts] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [shipperId, setShipperId] = useState();
  const [portsList, setPortsList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const searchParam = useLocation();
  const { user } = useContext(authData);
  const sourcePort = new URLSearchParams(searchParam.search).get("sourcePort");
  const destinationPort = new URLSearchParams(searchParam.search).get(
    "destinationPort"
  );
  const dimensions = new URLSearchParams(searchParam.search).get("dimensions");
  const bookingType = new URLSearchParams(searchParam.search).get(
    "bookingType"
  );
  const dueDate = new URLSearchParams(searchParam.search).get("duedate");
  const goodType = new URLSearchParams(searchParam.search).get("goodType");

  const [dimensionChange, setDimensionChange] = useState(dimensions);
  const [dueDateChange, setDueDateChange] = useState(dueDate);
  const [sourcePortName, setSourcePortName] = useState(sourcePort);
  const [desPortName, setDesPortName] = useState(destinationPort);
  const [confirmationModalshow, setConfirmationModalShow] =
    React.useState(false);
  const [selectedCost, setSelectedCost] = useState();
  const [modType, setModType] = useState("OrderConfirm");

  const findQuotes = () => {
    setIsLoading(true);
    if (sourcePortName && desPortName && dimensionChange && bookingType) {
      axios
        .post(
          "https://rish-shipping-backend-theta.vercel.app/api/booking/costs/",
          {
            sourcePort: sourcePortName,
            destinationPort: desPortName,
            dimensions: Number(dimensionChange),
            bookingType: bookingType,
          }
        )
        .then((res) => {
          setPorts(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (searchParam) {
      const sourcePort = new URLSearchParams(searchParam.search).get(
        "sourcePort"
      );
      const destinationPort = new URLSearchParams(searchParam.search).get(
        "destinationPort"
      );
      const dimensions = new URLSearchParams(searchParam.search).get(
        "dimensions"
      );
      const bookingType = new URLSearchParams(searchParam.search).get(
        "bookingType"
      );

      if (sourcePort && destinationPort && dimensions && bookingType) {
        axios
          .get("https://rish-shipping-backend-theta.vercel.app/admin/api/ports")
          .then((res) => {
            if (res.data) {
              setPortsList(res.data.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        axios
          .post(
            "https://rish-shipping-backend-theta.vercel.app/api/booking/costs/",
            {
              sourcePort: sourcePort,
              destinationPort: destinationPort,
              dimensions: Number(dimensions),
              bookingType: bookingType,
            }
          )
          .then((res) => {
            setPorts(res.data.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.error(err);
          });
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const createBookingRequest = async () => {
    setIsLoading(true);
    try {
      const responseData = await axios({
        method: "POST",
        url: "https://rish-shipping-backend-theta.vercel.app/api/booking/",
        data: {
          sourcePort: sourcePort,
          destinationPort: destinationPort,
          itemDimensions: parseInt(dimensions),
          deliveryDateRequired: dueDate,
          clientCompany: user._id,
          shippingCompany: shipperId,
          bookingCost: selectedCost,
          bookingType: bookingType,
          goodsType: goodType,
          //doorPickup
        },
      }).then((res) => {
        if (res.data) {
          setModType("OrderPlaced");
          setConfirmationModalShow(true);
          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const openModal = (id) => {
    setShipperId(id);
    setModalShow(true);
  };

  const OpenConfirmationModal = (shipper, cost) => {
    setShipperId(shipper);
    setSelectedCost(cost);
    setModType("OrderConfirm");
    setConfirmationModalShow(true);
  };

  return (
    <>
      {shipperId && (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          shipper={shipperId}
        />
      )}

      <ConfirmationModal
        show={confirmationModalshow}
        onHide={() => setConfirmationModalShow(false)}
        orderCreate={createBookingRequest}
        sourcePort={
          portsList && portsList.filter((id) => sourcePort === id._id)
        }
        destinationPort={
          portsList && portsList.filter((id) => destinationPort === id._id)
        }
        itemDimensions={parseInt(dimensions)}
        deliveryDateRequired={dueDate}
        shippingCompany={
          ports && ports.filter((id) => shipperId === id.shipper)
        }
        bookingCost={selectedCost}
        bookingType={bookingType}
        modType={modType}
        // agent={doc?.category?.name}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="quote-god">
          <div className="quote-res">
            <div className="top-panell">
              <div className="ports-desc">
                <select
                  id="business"
                  name="business"
                  className="dime-input"
                  value={sourcePortName}
                  onChange={(e) => {
                    setSourcePortName(e.target.value);
                  }}
                >
                  {portsList &&
                    portsList.map((doc) => (
                      <option key={doc.id}value={doc._id}>{doc.name}</option>
                    ))}
                </select>
                <span style={{ }}>to</span>

                <select
                  id="business"
                  name="business"
                  className="dime-inputA"
                  value={desPortName}
                  onChange={(e) => {
                    setDesPortName(e.target.value);
                  }}
                >
                  {portsList &&
                    portsList.map((doc) => (
                      <option key={doc.id} value={doc._id}>{doc.name}</option>
                    ))}
                </select>
              </div>
              <div className="divider">
                <span className="linediv"></span>
              </div>
              <div className="ports-props">
                <span
                  className="bkty"
                  style={{ marginTop: "5px", color: "white" }}
                >
                  {bookingType === "break bulk" ? "Break Bulk" : bookingType}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {bookingType === "break bulk" ? (
                    <div style={{ display: "flex", alignItems: "center" }} className="spacedo">
                      <input
                        type="text"
                        id="template-input"
                        placeholder=""
                        value={dimensionChange}
                        name="space"
                        className="dime-input2"
                        onChange={(e) => {
                          setDimensionChange(e.target.value);
                        }}
                      />
                      {bookingType === "break bulk" ? (
                        <span>
                          cm<sup>3</sup>
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <select
                        id="business"
                        name="business"
                        className="dime-input"
                        value={dimensionChange}
                        onChange={(e) => {
                          setDimensionChange(e.target.value);
                        }}
                      >
                        <option value={40}>40 Feet</option>
                        <option value={20}>20 Feet</option>
                      </select>
                    </div>
                  )}
                </div>

                <input
                  type="date"
                  id="template-input"
                  placeholder=""
                  className="dime-input"
                  value={dueDateChange}
                  min={new Date().toISOString().split("T")[0]}
                  name="needToarive"
                  onChange={(e) => {
                    setDueDateChange(e.target.value);
                  }}
                />
              </div>
              <div className="divider">
                {" "}
                <span className="linediv"></span>
              </div>
              <div className="btn-hs">
                <button
                  style={{
                    borderRadius: "8px",
                    border: "none",
                    outline: "none",
                    backgroundColor: "white",
                    padding: "5px 15px",
                    color: "#fb9263",
                    marginRight: "20px",
                  }}
                  onClick={findQuotes}
                >
                  Find
                </button>
              </div>
            </div>
          </div>
          <div className="select-filter-dropdown">
            <select className="status-dropdown-filter">
              <option>Default</option>
              <option>By Rating</option>
              <option>By Price</option>
            </select>
          </div>
          <div className="ports-list">
            <div style={{ width: "100%" }}>
              {ports &&
                ports.map((doc) => {
                  return (
                    <div key={doc.id}>
                      
                      <div className="sl-card1">
                        <div className="slc-name">{doc?.name}</div>
                        <div className="slc-deets">
                          <div className="slc-subdeets">
                            <span className="slc-smallTitle">Agent</span>
                            <span>{doc?.category?.name}</span>
                          </div>
                          <div className="slc-subdeets">
                            <span className="slc-smallTitle">Approx Cost</span>
                            <span>{doc.cost ? doc.cost : "NA"}</span>
                          </div>
                          <div className="slc-subdeets">
                            <button
                              className="rate-btn slc-btn"
                              onClick={() => openModal(doc.shipper)}
                            >
                              View Rating
                            </button>
                          </div>
                          <div className="slc-subdeets">
                            <button
                              className="book-btn slc-btn"
                              onClick={(e) =>
                                OpenConfirmationModal(doc.shipper, doc.cost)
                              }
                            >
                              Book
                            </button>
                          </div>
                        </div>
                        <div className="collapsible"></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Form1;
