import React, { useState, useRef, useEffect,useContext  } from 'react';
import "../Header/sidebar.css"
import authData from '../../utils/userContext'
import { Link } from 'react-router-dom';
import "./Header.css";
const Sidebar = () => {
    const { authStatus } = useContext(authData)
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={sidebarRef}
      className={`slide container-fluid ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}
    >
      <button className={`toggle-button ${isOpen ? 'active' : ''}`} onClick={handleToggle}>
      <img className ="sidebtn"src="/sidebar.svg" alt="" style={{ width: '30px', height: '30px' }}/>
      </button>
      {isOpen && (
        <div className=" sidemain row">
          <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
              <ul className="nav  flex-column">
                <li className="  nav-item ">
                  <a className=" btn nav-link" href="/#book-a-service">Book Service</a>
                </li>
                <li className="nav-item">
                  <a className=" btn nav-link" href="/aboutus">About Us</a>
                </li>
                <li className="nav-item">
                  <a className=" btn nav-link" href="/contact-us">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a className=" btn nav-link" href="/partner-with-us">Partner With Us</a>
                </li>
                {
          authStatus ?
            <div className="header-btns">
              <Link to="/user/orders"><button className="btn nav-link1 dash">Dashboard</button></Link>
            </div>
            :
            <div className="header-btns signinup">
              <Link to="/signup"><button className="signup1  " >Sign up</button></Link>
              <Link to="/login"> < button className="signin1  " >Sign in</button></Link>
            </div>
        }
               
              </ul>
            </div>
          </nav>
          <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            {/* Add your main content here */}
          </main>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
