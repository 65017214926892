import React, { useEffect, useState } from "react";
import "./contactus.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { IoMdCall, IoIosMail } from 'react-icons//io'
import { ImLocation } from 'react-icons/im'
import axios from 'axios'
import {motion} from "framer-motion"

const ContactUs = () => {
    const [userDetails, setUserDetails] = useState({
        email: "",
        name: "",
        phone: "",
        message: ""
    })
    const [isLoading, setIsLoading] = useState(false);
    const { email, name, phone, message } = { ...userDetails }

    const handleChange = (name, e) => {
        setUserDetails({ ...userDetails, [name]: e.target.value })
    }

    useEffect(() => {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }, []);

    useEffect(() => {
        const inputs = document.querySelectorAll(".contact-input");

        if (inputs) {
            inputs.forEach((input) => {
                input.addEventListener("focus", () => {
                    input.parentNode.classList.add("focus");
                });
                input.addEventListener("blur", () => {
                    if (input.value === "") {
                        input.parentNode.classList.remove("focus");
                    }
                });
            });
        }
    }, []);

    const handleSubmit = () => {
        if (email && name && message && phone) {
            setIsLoading(true)
            axios.post("https://rish-shipping-backend-theta.vercel.app/api/forms", {
                email,
                name,
                message,
                phoneNumber: phone,
                type: "Enquiry"
            }).then((res) => {
                if (res.data) {
                    toast.success(res.data.message)
                    setIsLoading(false)
                } else {
                    toast.error(res.data.message)
                    setIsLoading(false)
                }
                setUserDetails({ ...userDetails, name: '', email: "", message: "", phone: ""})
            }).catch((err) => {
                console.log(err.response.data.message)
                toast.error(err.response.data.message)
                setIsLoading(false)
            })
        } else {
            toast.error("All fields are required !")
        }
    }

    return (
        <div>
            <motion.div className="contact-us-main"
             initial={{ width: 0 }}
             animate={{ width: "100%" }}
             exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
             transition={{ type: "spring", stiffness:"50"  }}>
                <div className="contact-us-container">
                    <span className="big-circle"></span>
                    {/* <img src={shape} className="square" alt=""></img> */}
                    <div className="contact-us-form">
                        <div className="contact-info">
                            <h3 className="contact-info-title">Let's get in touch</h3>
                            <p className="contact-info-text">
                                We are dedicated to providing fast and reliable transportation
                                services for all of your shipping needs. If you have any
                                questions or concerns, please do not hesitate to contact us.
                                Our customer service team is available to assist you.
                            </p>
                            <div className="info">
                                <div className="information">
                                    <ImLocation />
                                    <p style={{ margin: '0 0 0 5px', padding: '0px' }}>Pac Auli Office, Auli Road, Joshimath.</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="information">
                                    <IoIosMail />
                                    <p style={{ margin: '0 0 0 5px', padding: '0px' }}>
                                        <a
                                            className="contact-us-link"
                                            href="mailto:info@pacauli.com"
                                        >
                                            info@pacauli.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="information">
                                    <IoMdCall />
                                    <p style={{ margin: '0 0 0 5px', padding: '0px' }}>
                                        <a className="contact-us-link" href="tel:917906114905">
                                            +91-7906114905
                                        </a>
                                    </p>
                                </div>
                            </div>
                            {/* <div className="social-media">
                                <p>Connect with us:</p>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/Pacauli/">
                                       
                                    </a>
                                    <a href="mailto:info@pacauli.com">
                                        
                                    </a>
                                    <a href="https://www.instagram.com/pac_auli/?hl=en">
                                        
                                    </a>
                                    <a href="https://www.google.com/search?q=pacauli&rlz=1C1CHBF_enIN863IN863&oq=pacauli+&aqs=chrome..69i57j69i59j69i60l6.1995j0j4&sourceid=chrome&ie=UTF-8#lrd=0x39a79dae40b6daf7:0x6564e03ad73a0fbf,1,,,">
                                        
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCLJwNA0dOYJk3AJb-yC3MKw">
                                        
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        <div className="contact-form">
                            <span className="circle one"></span>
                            <span className="circle two"></span>
                            <div className="contact-us-main-form">
                                <h3 className="contact-form-title">Contact Us</h3>

                                <div
                                    className="contact-form-input-container"
                                >
                                    <input
                                        type="text"
                                        name="name"
                                        className="contact-input"
                                        value={name}
                                        onChange={e => {
                                            handleChange("name", e)
                                        }}
                                    />

                                    <label className="contact-form-label" for="">
                                        Name
                                    </label>
                                    <span>Name</span>
                                </div>

                                <div
                                    className="contact-form-input-container"
                                >
                                    <input
                                        type="text"
                                        name="phNo"
                                        className="contact-input"
                                        value={phone}
                                        onChange={e => {
                                            handleChange("phone", e)
                                        }}
                                    />

                                    <label className="contact-form-label" for="">
                                        Phone Number
                                    </label>
                                    <span>Phone Number</span>
                                </div>


                                <div
                                    className="contact-form-input-container"
                                >
                                    <input
                                        id="Email"
                                        type="email"
                                        name="email"
                                        className="contact-input"
                                        value={email}
                                        onChange={e => {
                                            handleChange("email", e)
                                        }}
                                    />

                                    <label className="contact-form-label" for="">
                                        Email
                                    </label>
                                    <span>Email</span>
                                </div>


                                <div
                                    className="contact-form-input-container contact-textarea"
                                >
                                    <textarea
                                        name="message"
                                        cols=""
                                        rows=""
                                        className="contact-input"
                                        value={message}
                                        onChange={e => {
                                            handleChange("message", e)
                                        }}

                                    />

                                    <label className="contact-form-label" for="">
                                        Message
                                    </label>
                                    <span>Message</span>
                                </div>
                                {
                                    isLoading ?
                                        <button className="contact-submit-button">Loading...</button>
                                        :
                                        <button className="contact-submit-button" onClick={handleSubmit}>Submit</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default ContactUs;