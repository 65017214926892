import React from "react";
import "./ProfileCard.css";

const ProfileCard = (props) => {
  return (
    <div className="pc-god">
      <div className="pc-img">
        <img src={props.src} alt="" />
      </div>
      <div className="pc-title">
        <p>{props.name}</p>
        <span>{props.title}</span>
      </div>
      <div className="pc-description">{props.desc}</div>
    </div>
  );
};

export default ProfileCard;
