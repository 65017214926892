import { createContext, useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';


const UserContext = createContext({
    user: {},
    authStatus: false,
    triggerAuth: function () { },
    updateUser: function () { },
    logout: function () { }
})

export default UserContext;

export function UserProvider({ children }) {
    const [user, setUser] = useState({});
    const [authStatus, setAuthstatus] = useState(false);

    useEffect(() => {
        let userData = localStorage.getItem('userData')
        if (userData) {
            setUser(JSON.parse(userData))
            setAuthstatus(true)
        }
    }, [])

    const triggerAuth = (email, password, setIsLoading) => {
        setIsLoading(true)
        if (email && password) {
            axios.post("https://rish-shipping-backend-theta.vercel.app/api/company/login", {
                email,
                password
            }).then(res => {
                if (res.data) {
                    setUser(res.data.data.userData)
                    setAuthstatus(true)
                    toast.success('Login Succesfully Done !')
                    document.cookie = "authToken" + "=" + res.data.data.token
                    localStorage.setItem('userData', JSON.stringify(res.data.data.userData))
                }
                setIsLoading(false)
            })
                .catch(err => {
                    toast.error(err?.response?.data?.message ? err.response.data.message : "Invalid Credentials !")
                    console.error(err)
                    setIsLoading(false)
                });
        } else {
            setAuthstatus(false)
            setUser({})
            localStorage.removeItem('userData')
            document.cookie = "authToken" + "=" + ""
            setIsLoading(false)
        }
    }

    const logout = () => {
        localStorage.clear('userData')
        setUser({})
        setAuthstatus(false)
    }

    const updateUser = (updatedUserData) => {
        setUser(prevData => {
            return { ...prevData, ...updatedUserData }
        })
        localStorage.setItem('userData', JSON.stringify({...JSON.parse(localStorage.getItem("userData")), ...updatedUserData}))
    }

    const value = {
        authStatus: authStatus,
        user: user,
        triggerAuth: triggerAuth,
        updateUser: updateUser,
        logout: logout
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
}