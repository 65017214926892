import React, { useEffect, useState } from "react";
import "./ForgotPassword.css";
import $ from "jquery";
import Logo from "../../Assets/logo2.png";
import axios from "axios";
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Loader from "../../SharedComponents/Loader";
import {motion} from 'framer-motion'

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, []);

  const handleSubmit = () => {
    setIsLoading(true)
    axios
      .post("https://rish-shipping-backend-theta.vercel.app/api/company/reset-password", {
        email
      })
      .then((res) => {
        toast.error(res.data.message);
        console.log(res.data.message)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false)
      });
  };

  return (
    <motion.div className="login-god"
    initial ={{opacity:0}}
    animate ={{opacity:1}}
    exit={{opacity:0}}>
      <div className="blur"></div>
      <div className="login-subsec">
        <div className="login-form">
          <img src={Logo} alt="" className="img-logo" />
          <h4>Forgot Password?</h4>
          <input type="email" className="input-login" placeholder="Email Address" value={email} onChange={(e) => {
            setEmail(e.target.value)
          }} />
        {
          isLoading ?
          <button className="login-btn">Loading...</button>
          :
          <button className="login-btn" onClick={handleSubmit}>Proceed</button>
        }
          <p>LogIn Again ? <Link to="/login"><span>Login</span></Link></p>

        </div>
      </div>
    </motion.div>
  );
};

export default ForgotPassword;
