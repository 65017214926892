import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import Logo from '../../Assets/logo2.png'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Navigate, } from 'react-router-dom'
import authData from '../../utils/userContext'
import $ from 'jquery'
import Loader from "../../SharedComponents/Loader";
import {motion} from 'framer-motion'

const Login = () => {
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: ""
  })
  const { authStatus, triggerAuth } = useContext(authData)
  const { email, password } = { ...userDetails }
  const handleChange = (name, e) => {
    setUserDetails({ ...userDetails, [name]: e.target.value })
  }
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    if (email && password) {
      triggerAuth(email, password, setIsLoading)
    } else {
      toast.error('Please Fill All Fields !')
    }
  }

  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, [])

  return (
    <motion.div className="login-god"
    initial={{ width: 0 }}
    animate={{ width: "100%" }}
    exit={{ y: window.innerWidth, transition: { duration: 0.5 } }}
    transition={{ type: "spring", stiffness:"50"  }}
    >
      {authStatus ? <Navigate to="/user/profile" /> : null}
      <div className="blur"></div>
      <div className="login-subsec">
        <div className="login-form">
          <img
            src={Logo}
            alt="Logo Image"
            className="img-logo"
          />
          <h4>Welcome To Cargo Onboard</h4>
          <input className="input-logi" type="email" placeholder="Email Address" value={email} onChange={e => {
            handleChange("email", e)
          }} />
          <input className="input-logi" type="password" placeholder="Password" value={password} onChange={e => {
            handleChange("password", e)
          }} />
          {
            isLoading ?
              <button className="login-bt">Loading...</button>
              :
              <button className="login-bt" onClick={handleSubmit} onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.1)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}>Sign In</button>
          }
          <Link to="/forgot"><span>Forgot Password?</span></Link>
          <p>Don't have an account? <Link to="/signup">Sign up here.</Link></p>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;