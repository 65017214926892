import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./RatingsModal.css";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import moment from "moment";
import Loader from "../../SharedComponents/Loader";

export const MyVerticallyCenteredModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews([]);
    setIsLoading(true);
    axios
      .get(
        `https://rish-shipping-backend-theta.vercel.app/api/rating/shipper/${props.shipper}`
      )
      .then((res) => {
        setReviews(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [props.shipper]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rating and Reviews
        </Modal.Title>
      </Modal.Header>
      {
        isLoading ?
          <Modal.Body>
            <p>Loading...</p>
          </Modal.Body>
          :
          <Modal.Body>
            {reviews &&
              reviews.map((review, index) => {
                return (
                  <div   className={`${index !== 0 ? "ratings-gog" : "rate"}`}>
                    <div className="ratings-god">
                      <div className="ratings-top">
                        <div>
                          {review.companyId && review.companyId.name}
                          <span className="datetime">
                            on &nbsp;
                            {moment(review.createdAt).format(
                              "DD-MMM-YYYY - hh:mm A"
                            )}
                          </span>
                        </div>
                        <div className="rater">
                          <Rater
                            total={5}
                            rating={review.rating}
                            interactive={false}
                          />
                        </div>
                      </div>
                      <div className="ratings-bot">{review.review}</div>
                    </div>
                  </div>
                );
              })}
            {
              reviews.length === 0 ?
                <p>No Reviews and Rating found !</p>
                :
                null
            }
          </Modal.Body>
      }
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#fb9263",
            outline: "none",
            border: "none",
          }}
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
